function USER_UID(state) {
    return state.uid;
};

function USER_MESSAGES(state) {
    return state.messages;
};

function USER_CITY_ID(state) {
    return state.city_id;
};

function USER_AUTHORIZED(state) {
    return !!state.token;
};

function USER_INFO(state) {
    return state.info;
};

function USER_EMAIL(state) {
    return state.email;
};

function USER_COUNT_ACTIVE_ORDERS(state) {
    return state.activeOrders;
};

function USER_FAVOURITE_PRODUCTS(state) {
    return state.favProducts;
};

function USER_COUNT_FAVORITE_PRODUCTS(state) {
    return state.favCount;
};

function USER_COUNT_FAVORITE_DRUGSTORES(state) {
    return state.favorite_drugstores?.length;
};

function USER_FAVOURITE_DRUGSTORES(state) {
    return state.favDrugstores;
};

function USER_PHONE(state) {
    return state.phone;
};

function USER_CLIENT_CARD(state) {
    return state.client_card;
};

function USER_ID(state) {
    return state.id;
};

function USER_SUBSCRIPTIONS(state) {
    return state.subscriptions;
};

function FREE_DELIVERY_READY(state) {
    return state.preparedFreeDelivery;
};

function USER_PROMOCODES(state) {
    return state.promocodes;
};

function FRANCHISE_NAME(state) {
    console.log(state.franchName);
    return state.franchName;
};

function FRANCHISE_EMAIL(state) {
    console.log(state.franchEmail);
    return state.franchEmail;
};

function USER_ORDERS(state) {
    return state.orders;
}

function TOTAL_ORDER_PAGE(state) {
    return state.orders.totalPages;
}

function GET_REASONS_CANCEL(state) {
    return state.reason_cancel;
}

function USER_PURCHASES(state) {
    return state.purchases;
};

function USER_PRODUCTS(state) {
    return state.products;
};

function USER_CATEGORIES(state) {
    return state.categories;
};

function USER_FAVOURITES(state) {
    return state.favourites;
};

function USER_ADDRESS(state) {
    return state.address;
};

function BLOCK(state) {
    return state.block;
};

function TOKEN_HH(state) {
    return state.hhtoken;
};
function RESUME_HH(state) {
    return state.resume_hh;
};
export {
    USER_ID,
    USER_UID,
    USER_INFO,
    USER_PHONE,
    USER_EMAIL,
    USER_ORDERS,
    USER_CITY_ID,
    USER_MESSAGES,
    FRANCHISE_NAME,
    FRANCHISE_EMAIL,
    USER_AUTHORIZED,
    USER_PROMOCODES,
    TOTAL_ORDER_PAGE,
    USER_CLIENT_CARD,
    USER_SUBSCRIPTIONS,
    GET_REASONS_CANCEL,
    FREE_DELIVERY_READY,
    USER_FAVOURITE_PRODUCTS,
    USER_COUNT_ACTIVE_ORDERS,
    USER_FAVOURITE_DRUGSTORES,
    USER_COUNT_FAVORITE_PRODUCTS,
    USER_COUNT_FAVORITE_DRUGSTORES,
    USER_PURCHASES,
    USER_PRODUCTS,
    USER_CATEGORIES,
    USER_FAVOURITES,
    USER_ADDRESS,
    BLOCK,
    TOKEN_HH,
    RESUME_HH,
};
