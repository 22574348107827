import { request } from 'src/boot/axios';
import { is_expires } from 'assets/helpers';
import { uid, Cookies, LocalStorage } from 'quasar';
import axios from 'axios';

async function CHECK_TOKEN(context) {
    try {
        await context.dispatch('REFRESH_TOKEN');
    } catch (error) {
        console.error(error);

        return error;
    }
};

async function REFRESH_TOKEN(context, cookies) {
    const { token } = context.state;

    if (!token)
        return;

    if (is_expires(token.expires_in)) {
        const response = await request('post', '', {
            data: context.state.token,
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/auth/refresh-token`);

        if (response?.status) {
            context.commit('SET_TOKEN', response.payload);
            cookies
                ? cookies.set('token', response.payload, {
                    expires: 10,
                })
                : Cookies.set('token', response.payload, {
                    expires: 10,
                });
        } else {
            Cookies.remove('token');
            LocalStorage.remove('token');
            Cookies.remove('_uid');
            if (process.env.CLIENT) {
                window.location.reload(true);
            }
            throw error('нет токена', response);
        }

        return response?.status;
    } else {
        return true;
    }
};

async function CHECK_PHONE_NUMBER(_, payload) {
    return await request('post', '', {
        data: payload,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/auth/check-phone-number`);
};

async function SEND_PHONE_VERIFICATION_CODE(_, payload) {
    return await request('post', '', {
        data: payload,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/auth/send-code`);
};

async function VERIFY_PHONE_NUMBER(_, payload) {
    return await request('post', '', {
        data: payload,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/auth/verify-code`);
};

async function SIGNIN(context, payload) {
    context.commit('SIGNIN', payload);

    try {
        const response = await context
            .dispatch('GET_USER_DATA');

        if (response?.status) {
            context.commit('SET_USER_DATA', response.payload);
        }
    } catch (error) {
        console.error(error);
    }
};

async function GET_USER_DATA(context, payload) {
    await context.dispatch('CHECK_TOKEN');
    try {
        const response = await request('get', '', {
            token: context.state.token,
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/${context.state.token.local_id}`);

        if (response?.status) {
            context.commit('SET_USER_DATA', response.payload);
        }
    } catch (error) {
        return void console.error(error);
    }
};

async function CHECK_EMAIL_ADDRESS(_, payload) {
    return await request('post', '', {
        data: payload,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/auth/check-email-address`);
};

async function UPDATE_USER_CITY_ID(context, payload) {
    await context.commit('SET_USER_CITY_ID', payload);

    if (context.state.id) {
        await context.dispatch('UPDATE_USER_DATA', {
            city_id: payload,
        });
    }

    // if (process.env.CLIENT) {
    //     window.location.replace(new URL(window.location.href));
    // }
};

async function UPDATE_USER_DATA(context, payload) {
    await context.dispatch('CHECK_TOKEN');

    try {
        let result = await request('patch', '', {
            data: payload,
            token: context.state.token,
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/${context.state.id}`);

        if(payload.info)
            context.commit('UPDATE_USER_INFO', payload.info);
        if(result.payload.address)
            context.commit('UPDATE_USER_ADDRESS', result.payload.address);

    } catch (error) {
        return void console.error(error);
    }
};

async function FLASH_CALL(_, payload) {
    try {
        return await request('post', '', {
            data: payload,
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/auth/flash-call`);
    } catch(e) {
        console.log(e);
    }
};

async function FLASH_CALL_CHECK(_, payload) {
    try {
        return await request('post', '', {
            data: payload,
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/auth/flash-call/check`);
    } catch(e) {
        console.log(e);
    }
};

async function USER_GET_ACTIVE_ORDERS(context) {
    await context.dispatch('CHECK_TOKEN');
    try {
        const result = await request('get', '', {
            query: {
                isActive: true,
            },
            timeout: 5e3 * 4,
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/orders`);
        
        context.commit('SET_COUNT_OF_ACTIVE_ORDERS', result.results.length);

        return result;
    } catch (error) {
        context.commit('SET_COUNT_OF_ACTIVE_ORDERS', 0);

        return [];
    }
};

async function USER_GET_COUNT_ACTIVE_ORDERS(context) {
    await context.dispatch('CHECK_TOKEN');
    try {
        const result = await request('get', '', {
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/orders/counter`);
        
        context.commit('SET_COUNT_OF_ACTIVE_ORDERS', result.activeCount);

        return result;
    } catch (error) {
        context.commit('SET_COUNT_OF_ACTIVE_ORDERS', 0);

        return [];
    }
};

async function CANCEL_ORDER(context, payload) {
    await context.dispatch('CHECK_TOKEN');
    try{
        await request('post', '', {
            token: context.rootState.user.token,
            data: {
                reasonType: payload.reasonType, 
                reasonMessage: payload.reasonMessage,
            },
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/orders/${payload.id}/cancel`);
        return true;    
    } catch (e) {
        console.log(e);
        return false;
    }
    
};

async function GET_ORDER_PAYMENT_LINK(context, payload) {
    await context.dispatch('CHECK_TOKEN');

    try {
        return await request('get', '', {
            query: {
                success_url: payload.query.success_url,
                failure_url: payload.query.failure_url,
            },
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        }, 
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/orders/${payload.query.orderId}/payment-link`);
    } catch (e) {
        console.log(e);
    }
}

async function UPDATE_ORDER(context, { params }) {
    await context.dispatch('CHECK_TOKEN');

    return await request('put', `orders/${params.order_id}`, {
        data: params.order,
        timeout: 5e3 * 4,
        token: context.rootState.user.token,
    });
};

async function GET_ORDER_BY_ID(context, orderId) {
    await context.dispatch('CHECK_TOKEN');

    return await request('get', `orders/${orderId}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/orders/${orderId}`);
};

function SET_UID(context) {
    const { id } = context.state;

    if (id) {
        if (Cookies.get('_uid') !== id) {
            Cookies.set('_uid', id, {
                expires: 10,
            });
        }
    } else {
        if (!Cookies.get('_uid')) {
            const user_id = id || uid();

            Cookies.set('_uid', user_id, {
                expires: 10,
            });
        }
    }
    context.commit('SET_UID', Cookies.get('_uid'));
};

async function ADD_SUBSCRIPTION(context, product_id) {
    await context.dispatch('CHECK_TOKEN');
    try {
        await request('post', '', {
            params: [{
                user_id: context.state.id,
                product_id,
            }],
            token: context.state.token,

        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/${context.state.id}/add-subscription/${product_id}`);

        context.commit('ADD_USER_SUBSCRIPTION', product_id);
    } catch (error) {
        return void console.error(error);
    }
};

async function DELETE_SUBSCRIPTION(context, product_id) {
    await context.dispatch('CHECK_TOKEN');
    try {
        await request('post', '', {
            params: [{
                user_id: context.state.id,
                product_id,
            }],
            token: context.state.token,
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/${context.state.id}/delete-subscription/${product_id}`);

        context.commit('DELETE_USER_SUBSCRIPTION', product_id);
    } catch (error) {
        return void console.error(error);
    }
};

function CHECK_PREPARED_FREE_DELIVERY(context) {
    context.commit('PREPARED_FREE_DELIVERY', true);
};

async function GET_USER_PROMOCODES(context) {
    try {
        const result = await request('get', `users/${context.state.token.local_id}/promo-codes`, {
            token: context.state.token,
        });
    
        context.commit('SET_USER_PROMOCODES', result);
    
        return result;
    } catch (error) {
        return void console.error(error);
    }  
};

async function GET_USER_RECIPES(context, { data }) {
    await context.dispatch('CHECK_TOKEN');
    try {
        const phone = encodeURIComponent(`${context.state.phone.phone_number}`);
        const result = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            token: context.state.token,
        }, 
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/me?page=${data.page}&size=10${data.sort}&formTypes=Frm1071U`);

        return result;
    } catch (error) {
        return void console.error(error);
    }
};

async function GET_RECIPE(context, { data }) {
    await context.dispatch('CHECK_TOKEN');
    return await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.state.token,
    }, 
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/${data.id}`);
};

async function GET_RECIPE_RELEASES(context, { data }) {
    await context.dispatch('CHECK_TOKEN');
    try {
        const result = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            token: context.state.token,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/${data.id}/releases?page=${data.page}&size=10`);

        return result;
    } catch (error) {
        return void console.error(error);
    }
};

async function GET_RECIPE_PRODUCTS(context, { data }) {
    await context.dispatch('CHECK_TOKEN');
    try {
        const result = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            token: context.state.token,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/${data.id}/products?page=${data.page}&size=10`);

        return result;
    } catch (error) {
        return void console.error(error);
    }
};

async function GET_USER_AGREEMENTS_STATUS(context) {
    await context.dispatch('CHECK_TOKEN');
    const result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.state.token,
    }, 
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/agreements/status`);

    return result.status;
};

async function SEND_USER_AGREEMENTS(context) {
    await context.dispatch('CHECK_TOKEN');
    const result = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.state.token,
    }, 
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/agreements`);

    return result;
};

async function GET_USER_RECIPES_SHARES(context) {
    await context.dispatch('CHECK_TOKEN');
    const result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.state.token,
    }, 
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/shares?page=1&size=20`);

    return result.results;
};

async function CREATE_SHARE(context, data) {
    await context.dispatch('CHECK_TOKEN');
    const response = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'content-type': 'application/x-www-form-urlencoded',
        },
        token: context.state.token,
        data,
    },
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/shares`);


    return response;
};

async function DELETE_SHARE(context, { data }) {
    await context.dispatch('CHECK_TOKEN');
    const response = await request('delete', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'content-type': 'application/x-www-form-urlencoded',
        },
        token: context.state.token,
    },
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/shares/${data.id}`);

    return response;
};

async function VERIFY_SHARE(context, data) {
    await context.dispatch('CHECK_TOKEN');
    const response = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'content-type': 'application/x-www-form-urlencoded',
        },
        token: context.state.token,
        data,
    },
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/shares/${data.shareId}/verify`);

    return response;
}

async function GET_SHARE_RECIPES(context, { data }) {
    await context.dispatch('CHECK_TOKEN');
    try {
        const result = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            token: context.state.token,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/shared/${data.id}?page=${data.page}&size=10${data.sort}&formTypes=Frm1071U`);

        return result;
    } catch (error) {
        return void console.error(error);
    }
};

async function GET_USER_RECIPES_PERMISSIONS(context, { data }) {
    await context.dispatch('CHECK_TOKEN');
    const result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.state.token,
    }, 
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/shares/my/permissions?page=${data.page}&size=10`);

    return result;
};

async function GIVE_USER_PERMISSION(context, data) {
    await context.dispatch('CHECK_TOKEN');
    const result = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.state.token,
    }, 
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/shares/my/permissions/${data.shareId}/verify`);

    return result.results;
};

async function DELETE_PERMISSION(context, { data }) {
    await context.dispatch('CHECK_TOKEN');
    const response = await request('delete', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'content-type': 'application/x-www-form-urlencoded',
        },
        token: context.state.token,
    },
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recipes/shares/my/permissions/${data.id}`);

    return response;
};

async function GET_USER_FAVOURITES_COUNT(context) {
    await context.dispatch('CHECK_TOKEN');

    try {
        const response = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            token: context.state.token,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/favourites/products/count`);
    
        context.commit('SET_USER_FAV_COUNT', response.totalCount);
    } catch (error) {
        return void console.error(error);
    }

};

async function ADD_FAVOURITE_PRODUCT(context, payload) {
    await context.dispatch('CHECK_TOKEN');

    try {
        const response = await request('post', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            token: context.state.token,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/favourites/products/${payload}`);

        context.commit('SET_USER_FAV_COUNT', response.totalCount);

        try {
            ym(72234883,'reachGoal','klik-v-izbrannoe');
        } catch {}
    } catch (error) {
        return void console.error(error);
    }
};

async function ADD_FAVOURITE_DRUGSTORE(context, drugstore_id) {
    await context.dispatch('CHECK_TOKEN');
    try {
        return await request('post', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            token: context.state.token,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/favourites/pharmacies/${drugstore_id}`);
    } catch (error) {
        return void console.error(error);
    }
};

async function DELETE_FAVOURITE_PRODUCT(context, payload) {
    await context.dispatch('CHECK_TOKEN');

    try {
        const response = await request('delete', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            token: context.state.token,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/favourites/products/${payload}`);

        context.commit('SET_USER_FAV_COUNT', response.totalCount);
    } catch (error) {
        return void console.error(error);
    }
};

async function DELETE_FAVOURITE_DRUGSTORE(context, drugstore_id) {
    await context.dispatch('CHECK_TOKEN');

    try {
        return await request('delete', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            token: context.state.token,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/favourites/pharmacies/${drugstore_id}`);
    } catch (error) {
        return void console.error(error);
    }
};

async function GET_USER_FAVOURITES_DRUGSTORES(context) {
    await context.dispatch('CHECK_TOKEN');

    try {
        const result = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            token: context.state.token,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/favourites/pharmacies?page=1&size=20`);

        context.commit('SET_USER_FAV_DRUGSTORES', result.results);
    } catch (error) {
        return void console.error(error);
    }
};

async function GET_USER_FAVOURITES_PRODUCTS(context, payload) {
    await context.dispatch('CHECK_TOKEN');

    const result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.state.token,
    },
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/favourites/products?page=${payload.page}&size=20`); 

    
    context.commit('SET_USER_FAV_PRODUCTS', result.products);

    if(!payload.showMore) {
        context.commit('SET_USER_FAV', result);
    } else {
        context.commit('ADD_USER_FAV', result.products);
    }
    
};

async function USER_GET_ORDERS(context, { ...query }) {
    await context.dispatch('CHECK_TOKEN');
    try {
        const result = await request('get', '', {
            query: query[0],
            timeout: 5e3 * 4,
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/orders`);
        
        if(query[0].page > 1) {
            context.commit('MORE_USER_ORDERS', result);
        } else {
            context.commit('SET_USER_ORDERS', result);
        }        
        return result;
    } catch (error) {
        console.log(error);
        return [];
    }
};

async function GET_CANCEL_ORDER_REASONS(context) {

    await context.dispatch('CHECK_TOKEN');
    try {
        const result = await request('get', '', {
            timeout: 5e3 * 4,
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/orders/reasons`);

        const reasonsList = Object.keys(result).map(key => ({label: result[key], value: key, color: 'positive'}));

        context.commit('SET_CANCEL_ORDER_REASONS', reasonsList);
    } catch (error) {
        console.log(error);
        return [];
    }
};

async function USER_ADDRESS_DELIVERY_SEND(context, payload) {
    await context.dispatch('CHECK_TOKEN');
    
    const { address_id, deliveryTariffId, orderId } = payload;
    const data = {
        deliveryTariffId,
        address_id,
    };
    try {
        
        const result = await request('post', '', {
            timeout: 5e3 * 4,
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            data,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/orders/${orderId}/add-delivery`);


        return result;
    } catch (error) {
        console.log(error);
        return [];
    }
};

async function DELETE_ORDER(context, payload) {
    await context.dispatch('CHECK_TOKEN');

    try {
        const result = await request('delete', '', {
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/orders/${payload}`);
    } catch (error) {
        console.log(error);
        return [];
    }
};

async function GET_USER_PURCHASES(context, query) {
    await context.dispatch('CHECK_TOKEN');

    try {
        const result = await request('get', '', {
            query: query[0],
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/orders/purchases`);

        if(query[0].page > 1) {
            context.commit('MORE_USER_PURCHASES', result);
        } else {
            context.commit('SET_USER_PURCHASES', result);
        }     

        return result;
    } catch (error) {
        console.log(error);
    }
};

async function GET_USER_PRODUCTS(context, query) {
    await context.dispatch('CHECK_TOKEN');

    try {
        const result = await request('get', '', {
            query: query[0] || query.query,
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recommendations/products`);

        if(query.save != false)
            context.commit('SET_USER_PRODUCTS', result);

        return result;
    } catch (error) {
        console.log(error);
    }
}

async function GET_USER_CATEGORIES(context, query) {
    await context.dispatch('CHECK_TOKEN');

    try {
        const result = await request('get', '', {
            query: query[0],
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/recommendations/categories`);

        context.commit('SET_USER_CATEGORIES', result);

        return result;
    } catch (error) {
        console.log(error);
    }
}

async function POST_SEND_REVIEW(context, data) {
    await context.dispatch('CHECK_TOKEN');
    
    try {
        
        const result = await request('post', '', {
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            data,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/reviews/order-review`);


        return result;
    } catch (error) {
        console.log(error);
    }
}

async function CHANGE_REVIEW(context, data) {
    await context.dispatch('CHECK_TOKEN');
    
    try {
        
        const result = await request('put', '', {
            token: context.rootState.user.token,
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            data,
        },
        `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/reviews/order-review`);


        return result;
    } catch (error) {
        console.log(error);
    }
};

async function GET_TOKEN_FROM_YA(context, payload) {
    const response = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'authorization': payload, 
        },
    },
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/auth/oauth?oauth_type=ya`);

    if (response?.status) {
        context.commit('SET_TOKEN', response.payload);
        Cookies.set('token', response.payload, {
            expires: 10,
        });

        context.commit('SIGNIN', response.payload);

        return true;
    } 
    
    return response.status;
};

async function GET_TOKEN_FROM_TIN(context, payload) {
    const response = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'authorization': payload, 
        },
    },
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/auth/oauth?oauth_type=tf`);

    if (response?.status) {
        context.commit('SET_TOKEN', response.payload);
        Cookies.set('token', response.payload, {
            expires: 10,
        });

        context.commit('SIGNIN', response.payload);

        return true;
    } 
    
    return response.status;
};

async function GET_TOKEN_FROM_VK(context, payload) {
    const response = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'authorization': payload.code, 
        },
    },
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/users/auth/oauth?oauth_type=vk&uuid=${payload.device_id}&code_verifier=${payload.state}`);

    if (response?.status) {
        context.commit('SET_TOKEN', response.payload);
        Cookies.set('token', response.payload, {
            expires: 10,
        });

        context.commit('SIGNIN', response.payload);

        return true;
    } 
    
    return response.status;

};

async function GET_TOKEN_FROM_HH(context, payload){
    try {
        const response = await axios.post(`/hh-auth?code=${payload}`);
        if (response.status === 200) {
            const expirationDate = new Date().getTime() + response.data.expires_in * 60000;
            localStorage.setItem('hh-access', JSON.stringify({ token: response.data.access_token, expirationDate }));
        } else if (response.status === 403) {
            const response = await axios.post('/hh-re-auth');
            if (response.status === 200) {
                const expirationDate = new Date().getTime() + response.data.expires_in * 60000;
                localStorage.setItem('hh-access', JSON.stringify({
                    token: response.data.access_token,
                    expirationDate,
                }));
            }
        }
        return response;
    } catch (err) {
        return err;
    }
}
async function GET_TOKEN_FROM_LS(context){
    try {
        const token = JSON.parse(localStorage.getItem('hh-access'));
        if (token.token){
            if (new Date().getTime() < token.expirationDate) {
                context.commit('SET_HH_TOKEN',token.token);
                return true;
            } else {
                localStorage.removeItem('hh-access');
                return null;
            }
        } else{
            return false; 
        } 
    }catch (err){
        console.log(err);
    }
}

async function GET_RESUME_HH(context,payload){
    try {
        const response = await axios.get('https://api.hh.ru/resumes/mine',{
            headers:{
                'Authorization': `Bearer ${payload}`,
                'HH-User-Agent': 'artes-grp/1.0 (admin@etabl.com)',
            },
        });
        if (response.status === 200) {
            context.commit('SET_HH_RESUME',response.data.items);
        }
    } catch (err) {
        return err;
    }
}
export {
    SIGNIN,
    SET_UID,
    FLASH_CALL,
    CHECK_TOKEN,
    CANCEL_ORDER,
    UPDATE_ORDER,
    REFRESH_TOKEN,
    GET_USER_DATA,
    USER_GET_ORDERS,
    GET_ORDER_BY_ID,
    ADD_SUBSCRIPTION,
    UPDATE_USER_DATA,
    FLASH_CALL_CHECK,
    CHECK_PHONE_NUMBER,
    GET_USER_PROMOCODES,
    VERIFY_PHONE_NUMBER,
    CHECK_EMAIL_ADDRESS,
    UPDATE_USER_CITY_ID,
    DELETE_SUBSCRIPTION,
    ADD_FAVOURITE_PRODUCT,
    USER_GET_ACTIVE_ORDERS,
    GET_ORDER_PAYMENT_LINK,
    ADD_FAVOURITE_DRUGSTORE,
    GET_CANCEL_ORDER_REASONS,
    DELETE_FAVOURITE_PRODUCT,
    DELETE_FAVOURITE_DRUGSTORE,
    CHECK_PREPARED_FREE_DELIVERY,
    SEND_PHONE_VERIFICATION_CODE,
    GET_USER_RECIPES,
    USER_ADDRESS_DELIVERY_SEND,
    GET_RECIPE,
    GET_RECIPE_RELEASES,
    GET_RECIPE_PRODUCTS,
    GET_USER_AGREEMENTS_STATUS,
    SEND_USER_AGREEMENTS,
    GET_USER_RECIPES_SHARES,
    CREATE_SHARE,
    VERIFY_SHARE,
    GET_SHARE_RECIPES,
    DELETE_SHARE,
    GET_USER_RECIPES_PERMISSIONS,
    GIVE_USER_PERMISSION,
    DELETE_PERMISSION,
    GET_USER_FAVOURITES_COUNT,
    GET_USER_FAVOURITES_DRUGSTORES,
    GET_USER_FAVOURITES_PRODUCTS,
    DELETE_ORDER,
    USER_GET_COUNT_ACTIVE_ORDERS,
    GET_USER_PURCHASES,
    GET_USER_PRODUCTS,
    GET_USER_CATEGORIES,
    POST_SEND_REVIEW,
    CHANGE_REVIEW,
    GET_TOKEN_FROM_YA,
    GET_TOKEN_FROM_TIN,
    GET_TOKEN_FROM_VK,
    GET_TOKEN_FROM_HH,
    GET_TOKEN_FROM_LS,
    GET_RESUME_HH,
};
