export default function () {
    return {
        stylesAccessibility: {
            images: 'block',
            letterSpacing: 0,
        },
        isActiv: false,
        isActivPanel: false,
        count: 0,
    };
}
