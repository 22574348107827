import { request } from 'src/boot/axios';

/*async function GET_VACANCY({commit}, regionId) {
    try {
        const response = await request('GET', '', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            query: {
                region: regionId.region,
            },
            
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/vacancies`);
        
        commit('SET_VACANCY', response);
    } catch (error) {
        console.error(error);

        return error;
    }
};*/

async function GET_VACANCY({commit}, regionId) {
    try {
        const response = await request('GET', '', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            query: {
                region: regionId.region,
            },

        }, 'https://api.hh.ru/vacancies?employer_id=1621201&per_page=100');

        commit('SET_VACANCY', response);
    } catch (error) {
        console.error(error);

        return error;
    }
};


export {
    GET_VACANCY,
};