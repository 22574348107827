export default function () {
    return {
        meta: null,
        title: null,
        description: null,
        filters: null,
        products: null,
        categories: null,
        freeDelivery: false,
        categoriesPrepared: null,
        categoriesUrlToId: null,
        categoriesIdToUrl: null,
        currentCategory: '000000001',
        categoriesFreeDeliveryPrepared: null,
        currentCatalogCategory: null,

        newCategories: null,
        newCurrentCategory: null,
    };
}
