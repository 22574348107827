import { deepCopy } from './index';
import { Platform } from 'quasar';

/**
 * Изменени кол-ва товара
 * @param { Boolean } pm dev or int
 * @param { Object } product product
 * @param { Object } store instance of store
 * @param { Boolean } userAuthorized auth of user
 * @param { Object } $q instance of Quasar
 */

async function setAmount(pm, product, store, userAuthorized, $q) {
    const amount = pm
        ? product.amount + 1
        : !(product.amount - 1)
            ? product.amount
            : product.amount - 1;

    // костыль
    const cityDelivery = store.getters['basket/BASKET_CITY_DELIVERY'];
    //

    store.commit('basket/SET_BASKET_LOADING', true);

    const is_today = store.getters['basket/BASKET_IS_TODAY'];

    // если пользователь авторизован
    if (userAuthorized) {
        try {
            await store.dispatch('basket/BASKET_PUT_PRODUCT', {
                product: {
                    id: product.id,
                    amount: amount || 1,
                    checked: product.checked,
                },
                is_today,
            });
        } catch (error) {
            $q.notify({
                color: 'negative',
                message: 'Произошла ошибка. Повторите попытку позже',
            });
        };
    } else {
        store.commit('localStorage/SET_AMOUNT', {
            id: product.id,
            amount,
        });
    }

    // костыль
    if (cityDelivery)
        await setCityDelivery(cityDelivery, store, $q);
    await getInventoryList(store);
    //

    store.commit('basket/SET_BASKET_LOADING', false);
};

async function delProductInCart(id, store, userAuthorized, $q) {
    // костыль
    const cityDelivery = store.getters['basket/BASKET_CITY_DELIVERY'];
    //

    store.commit('basket/SET_BASKET_LOADING', true);

    const is_today = store.getters['basket/BASKET_IS_TODAY'];

    // если пользователь авторизован
    if (userAuthorized) {
        try {
            await store.dispatch('basket/BASKET_DEL_PRODUCT', {
                id,
                is_today,
            });
        } catch (error) {
            $q.notify({
                color: 'negative',
                message: 'Произошла ошибка. Повторите попытку позже',
            });
        };
    } else {
        store.commit('localStorage/DELETE_ITEM', id);
    }

    // костыль
    if (cityDelivery)
        await setCityDelivery(cityDelivery, store, $q);
    //
    await getInventoryList(store);

    store.commit('basket/SET_BASKET_LOADING', false);
};

async function setCheck(product, store, userAuthorized, $q) {
    // костыль
    const cityDelivery = store.getters['basket/BASKET_CITY_DELIVERY'];
    //

    store.commit('basket/SET_BASKET_LOADING', true);

    const is_today = store.getters['basket/BASKET_IS_TODAY'];

    // если пользователь авторизован
    if (userAuthorized) {
        try {
            await store.dispatch('basket/BASKET_PUT_PRODUCT', {
                product: {
                    id: product.id,
                    amount: product.amount,
                    checked: !product.checked,
                },
                is_today,
            });
        } catch (error) {
            $q.notify({
                color: 'negative',
                message: 'Произошла ошибка. Повторите попытку позже',
            });
        };
    } else {
        store.commit('localStorage/SET_CHECK', product.id);
    }

    // костыль
    if (cityDelivery)
        await setCityDelivery(cityDelivery, store, $q);
    await getInventoryList(store);
    //

    store.commit('basket/SET_BASKET_LOADING', false);
};

async function* createOrderWithPayment(order, store, router, $q, promoCode) {
    yield true;

    const is_today = true;
    const deliveryType = store.getters['basket/BASKET_METHOD_WAY_GET'];
    
    const destination_city_id = deliveryType[0] == 1 ? store.getters['basket/BASKET_CITY_DELIVERY'].city_id : '';
    const drugstore_id = deliveryType[0] == 1 ? store.getters['basket/BASKET_CITY_DELIVERY_GOODS_LIST'].today[0].drugstore_id : '';

    let query = deliveryType[0] == 1
        ? {
            is_today,
            drugstore_id,
            destination_city_id,
        }
        : '';

    // ссылка для оплаты
    let paymentLink;

    const prods = [];

    const products = store.getters['basket/BASKET_GOODS_LIST'];

    products.forEach(({id, total_price, amount, name, checked}) => {
        if (checked) {
            prods.push({
                id,
                name,
                'price': total_price,
                'quantity': amount,
            });
        }
    });

    try {
        const { order_id } = await store.dispatch('basket/BASKET_CREATE_ORDER', {
            path: deliveryType[0] == 1 ? '/from_variant' : '',
            query,
            order,
        });
        try {
            window.dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'purchase' : {
                        'actionField': {
                            'id': order_id,
                            'coupon' : promoCode.value,
                        },
                        'products' : prods,
                    },
                },
            });
        } catch {}
        
        try {
            paymentLink = await store.dispatch('basket/BASKET_GET_ORDER_PAYMENT_LINK', {
                path: `${order_id}/payment-link`,
                query: {
                    success_url: `https://${process.env.APP_SERVICE_HOSTNAME}/payment-complite`,
                    failure_url: `https://${process.env.APP_SERVICE_HOSTNAME}/payment-failed`,
                },
            });
        } catch (e) {
            $q.notify({
                color: 'negative',
                message: 'Произошла ошибка. Повторите попытку позже ' + e,
            });
        }

        yield false;

        // состояние загрузки
        store.commit('basket/SET_BASKET_FULL_LOADING', true);

        // обновялем данные корзины
        await store.dispatch('basket/GET_BASKET');

        // редирект на главную
        await router.push('/');

        // состояние загрузки
        store.commit('basket/SET_BASKET_FULL_LOADING', false);

    } catch (e) {
        if (e.response.status === 406) {
            window.location.reload(true);
        }
        else {
            console.log(e);
        }
        yield false;
    }

    window.open(paymentLink, '_self');
};

async function createOrderWithoutPayment(order, store, router, $q, promoCode) {

    try {
        const { order_id } = await store.dispatch('basket/BASKET_CREATE_ORDER', {
            path: '',
            order,
            query: '',
        });
    
        const prods = [];

        const products = store.getters['basket/BASKET_GOODS_LIST'];

        products.forEach(({id, total_price, amount, name, checked}) => {
            if (checked) {
                prods.push({
                    id,
                    name,
                    'price': total_price,
                    'quantity': amount,
                });
            }
        });

        await store.dispatch('basket/GET_BASKET');

        if (order_id) {

            $q.notify({
                color: 'positive',
                message: 'Ваш заказ оформлен',
            });

            try {
                window.dataLayer.push({
                    'ecommerce': {
                        'currencyCode': 'RUB',
                        'purchase' : {
                            'actionField': {
                                'id': order_id,
                                'coupon' : promoCode.value,
                            },
                            'products' : prods,
                        },
                    },
                });
            } catch {}

            store.dispatch('user/USER_GET_ACTIVE_ORDERS');

            router.push(`/order-without-payment-created?id=${order_id}`);
        }
    } catch (e) {
        if (e.response.status === 406) {
            window.location.reload(true);
        }
        else {
            console.log(e);
        }
    }
}

// получаем список всех аптек
async function getAllDrugstores(store) {
    if (store.getters['basket/BASKET_ALL_DRUGSTORES']) return;

    const { result } = await store.dispatch('drugstores/GET_ALL_DRUGSTORES');

    let allDrugstores = {};

    result.forEach((drugstore) => {
        allDrugstores[drugstore.drugstore_id] = Object.assign({}, drugstore);
    });

    store.commit('basket/SET_BASKET_ALL_DRUGSTORES', allDrugstores);
};

// получаем инвентори лист
async function getInventoryList(store) {
    // результат
    const result = [];

    const is_today = store.getters['basket/BASKET_IS_TODAY'];

    // инвентори лист
    const inventoryList = await store.dispatch('basket/BASKET_GET_INVENTORY_LIST', {
        is_today,
    });

    // список всех аптек
    const allDrugstores = store.getters['basket/BASKET_ALL_DRUGSTORES'];

    if (!allDrugstores)
        return;

    for (let item of inventoryList) {
        if (!allDrugstores[item.drugstore_id])
            break;

        const currentDrugstore = deepCopy(allDrugstores[item.drugstore_id]);

        if (!currentDrugstore)
            continue;

        currentDrugstore['packing_amount'] = 0;
        currentDrugstore['packing_available'] = 0;
        currentDrugstore['list_items'] = item.list_items;
        currentDrugstore['total_price'] = item.total_price;
        currentDrugstore['status_code'] = item.status_code;
        currentDrugstore['total_count'] = item.total_count;
        currentDrugstore['total_products'] = item.total_products;
        currentDrugstore['date_of_delivery'] = item.date_of_delivery;

        currentDrugstore['list_items'].forEach((prodDrug) => {
            let el = store.getters['basket/BASKET_GOODS_LIST'].find((prod) => prodDrug.product_id == prod.id);

            if (el) {
                prodDrug['images'] = el.images;
                prodDrug['status_code'] = el.status_code;
                prodDrug['subtitle'] = el.subtitle;
            }
        });

        item.list_items.forEach((item) => {
            currentDrugstore['packing_available'] += item.available;
            currentDrugstore['packing_amount'] += item.amount;
        });

        result.push(currentDrugstore);
    }

    store.commit('basket/SET_BASKET_INVENTORY_LIST', result);
};

async function setCityDelivery(city, store, $q) {
    try {
        if (!store.getters['basket/BASKET_TOTAL_COUNT'] && !store.getters['basket/BASKET_TODAY_TOTAL_COUNT']) 
            return;

        await store.dispatch('basket/BASKET_DESTINATION_CITY_ID', city);
    } catch (error) {
        $q.notify({
            color: 'negative',
            message: 'Произошла ошибка. Повторите попытку позже',
        });
    };
};

async function checkImpossible(store, basket) {
    const fBasket = basket;

    // const check = async (list) => {
    //     list.forEach(async (product) => {
    //         // если выбрано меньше чем доступно и достпуно как минимум 1
    //         if (product.amount > (product.available && product.available)) {
    //             await store.dispatch('BASKET_PUT_PRODUCT', {
    //                 product: {
    //                     id: product.id,
    //                     amount: product.amount,
    //                     checked: product.checked,
    //                 },
    //             });
    //         }

    //         // если товар недоступен
    //         if (!product.available) {
    //             await store.dispatch('BASKET_PUT_PRODUCT', {
    //                 product: {
    //                     id: product.id,
    //                     amount: product.amount,
    //                     checked: false,
    //                 },
    //             });

    //             fBasket.impossibleOrderList
    //                 ? fBasket.impossibleOrderList.push(product)
    //                 : fBasket.impossibleOrderList = [product];
    //         }
    //     });
    // };

    // await check(fBasket.goods_list);
    // // await check(fBasket.today_goods_list);

    return fBasket;
};

export {
    setCheck,
    setAmount,
    checkImpossible,
    setCityDelivery,
    getInventoryList,
    delProductInCart,
    getAllDrugstores,
    createOrderWithPayment,
    createOrderWithoutPayment,
};
