import { Storage } from 'assets/helpers/storage';

function SET_LOCAL_DATA(state, payload) {
    state.localData = payload || {
        products: [],
        favourites: [],
        drugstoreId: [],
    };
};

function SAVE_LOCAL_DATA(state) {
    Storage.saveLocalData(state.localData);
};

function ADD_TO_CART(state, product) {
    if (state.localData.products.find((item) => item.id === product.id))
        return;

    state.localData.products.push({
        ...product,
        amount: 1,
        checked: true,
    });

    SAVE_LOCAL_DATA(state);
};

function ADD_FAVOURITE_PRODUCT(state, id) {
    state.localData.favourites.push(id);

    SAVE_LOCAL_DATA(state);

    try {
        ym(72234883,'reachGoal','klik-v-izbrannoe');
    } catch {}
};

function DELETE_FAVOURITE_PRODUCT(state, id) {
    let index = state.localData.favourites.indexOf(state.localData.favourites.find((item) => item === id));

    if (~index) {
        state.localData.favourites.splice(index, 1);
        SAVE_LOCAL_DATA(state);
    }
};

function DELETE_ALL_FAVOURITES(state) {
    state.localData.favourites = [];
    SAVE_LOCAL_DATA(state);
}

function SET_AMOUNT(state, { id, amount }) {
    for (const product of state.localData.products) {
        if (product.id === id) {
            if (amount < 1) {
                state.localData.products = state.localData.products
                    .filter((item) => item.id !== id);
            } else {
                product.amount = amount;
            }

            SAVE_LOCAL_DATA(state);

            break;
        }
    }
};

function DELETE_ITEMS(state, ids) {
    state.localData.products = state.localData.products
        .filter((item) => !ids.includes(item.id));

    SAVE_LOCAL_DATA(state);
};

function SET_CHECK(state, id) {
    for (const product of state.localData.products) {
        if (product.id === id) {
            product.checked = !product.checked;
            SAVE_LOCAL_DATA(state);
            break;
        }
    }
};

function SET_CHECK_ALL(state, checked) {
    state.localData.products
        .forEach((product) => {
            product.checked = checked;
        });

    SAVE_LOCAL_DATA(state);
};

function CLEAR_CART(state) {
    state.localData.products = [];

    SAVE_LOCAL_DATA(state);
};

function DELETE_ITEM(state, id) {
    state.localData.products = state.localData.products.filter((item) => item.id !== id);
    Storage.saveLocalData(state.localData);
};

function SET_DRUGSTORE(state, drugstore_id) {
    state.localData.drugstore_id = drugstore_id;

    SAVE_LOCAL_DATA(state);
};

export {
    SET_LOCAL_DATA,
    ADD_TO_CART,
    ADD_FAVOURITE_PRODUCT,
    DELETE_FAVOURITE_PRODUCT,
    DELETE_ALL_FAVOURITES,
    SET_AMOUNT,
    DELETE_ITEMS,
    SET_CHECK,
    DELETE_ITEM,
    SET_CHECK_ALL,
    CLEAR_CART,
    SET_DRUGSTORE,
};
