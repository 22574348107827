import { request } from 'src/boot/axios';

async function GET_CATEGORIES(context) {
    return (await request('get', 'categories', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
    })).payload;
};

async function GET_NCATEGORIES(context, payload) {
    return ( await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/categories?delivery=${payload}`)).categories;
};


async function GET_CATEGORIES_FREE_DELIVERY(context, data) {
    return (await request('get', 'search/delivery-catalog', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
    }));
};

async function GET_FILTERS_BY_CATEGORY(context, data) {
    return await request('get', `filters/${data.category_id}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
    });
};

async function GET_PREVIEWS_BY_CATEGORY(context, {categoryId, query}) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('get', `previews/by_category/${categoryId}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        token: context.rootState.user.token,
        query,
    });
};

async function GET_NCATEGORY_BY_SLUG(context, { data }) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });
    
    return await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/category_detail/${data.slug}?delivery=${data.freeDelivery}`);
};

async function GET_FREE_CATALOG_COUNT(context) {
    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/products/free-delivery-count`);

    return result.count;
}

export {
    GET_CATEGORIES,
    GET_FILTERS_BY_CATEGORY,
    GET_PREVIEWS_BY_CATEGORY,
    GET_CATEGORIES_FREE_DELIVERY,

    GET_NCATEGORIES,
    GET_NCATEGORY_BY_SLUG,
    GET_FREE_CATALOG_COUNT,
};