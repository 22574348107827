function CATALOG_FILTERS(state) {
    return state.catalogFilters;
};

function PRODUCTS(state) {
    return state.products;
};

function PRODUCTS_COUNT(state) {
    return state.productsCount;
};

function CATEGORY_TREE(state) {
    return state.categoryTree;
};

function NESTED_CATEGORIES(state) {
    return state.nestedCategories;
};

function CRUMB_PATH(state) {
    return state.crumbPath;
};

function FILTERS_FROM_URL(state) {
    return state.filtersFromURL;
};

function CATALOG_FILTERS_IS_EMPTY(state) {
    if (!state.catalogFilters) return true;
    
    for (const [key, value] of Object.entries(state.catalogFilters)) {
        if (value.values.length) return false;
    }

    return true;
};

function LOADING(state) {
    return state.loading;
};

function PRODUCTS_FREE_CATALOG(state) {
    return state.productsFreeCatalog;
};

function TAG_DETAIL(state) {
    return state.tagDetail;
};

function ALL_TAGS(state) {
    return state.tagsList;
};

function SCROLL_ID(state) {
    return state.scrollId;
};

function CATALOG_ID(state) {
    return state.catalogId;
};

export {
    ALL_TAGS,
    LOADING,
    PRODUCTS,
    CRUMB_PATH,
    TAG_DETAIL,
    CATEGORY_TREE,
    PRODUCTS_COUNT,
    CATALOG_FILTERS,
    FILTERS_FROM_URL,
    NESTED_CATEGORIES,
    PRODUCTS_FREE_CATALOG,
    CATALOG_FILTERS_IS_EMPTY,
    SCROLL_ID,
    CATALOG_ID,
};