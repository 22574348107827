import { request } from 'src/boot/axios';

async function GET_MARKERS(context, data = {}) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    try {
        const response = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
                'x-user-id': context.rootState.user.id,
            },
            query: data,
            token: context.rootState.user.token,
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/products/marker` );

        context.commit('SET_DISCOUNT', response);

    } catch (error) {
        return void console.error(error);
    }
};

async function FETCH_ADVERTISMENT(context, channel) {
    try {
        if (context.state.advertisement)
            return context.state.advertisement;
            
        const response = await request('get', '', {
            headers: {
                'x-channel': channel,
                'x-city-id': context.rootState.user.city_id,
            },
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/marketing/all?channel=${channel}&page=1&size=20`);

        context.commit('SET_ADVERTISMENT', response);

    } catch (error) {
        return void console.error(error);
    }
};

async function FETCH_ADVERTISMENT_BY_ID(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    const response = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        token: context.rootState.user.token,
        data: {
            'sort': 'price_desc',
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/marketing/${data}`);

    context.commit('SET_SINGE_ADVERTISMENT', response);

    return response;
};

async function GET_HISTORY(context) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    try {
        const response = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
                'x-user-id': context.rootState.user.id,
            },
            token: context.rootState.user.token,
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/products/user-viewed`);

        context.commit('SET_HISTORY', response);
    } catch (error) {
        return void console.error(error);
    }
};


async function GET_ARTICLES(context, data = {}) {
    try {
        const response = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
                'x-user-id': context.rootState.user.id,
            },
            query: data,
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v2/seo/articles?size=10&is_active=true`);

        context.commit('SET_ARTICLES', response);
        
        return response;
    } catch (error) {
        return void console.error(error);
    }
}

async function GET_ARTICLE_BY_SLUG(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });
    
    const response = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v2/seo/articles/${data}`);

    context.commit('SET_ARTICLE', response);

    return response;
}

async function GET_ARTICLES_TAGS(context) {
    try {
        const response = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
                'x-user-id': context.rootState.user.id,
            },
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v2/seo/tags`);

        context.commit('SET_ARTICLES_TAGS', response);

        return response;
    } catch (error) {
        return void console.error(error);
    }
}

async function SET_ARTICLE_REVIEW(context, data) {
    const result = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
            'x-captcha-id': data.captchaId,
        },
        data: data.info,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/reviews/article-review`);

    return result;
}

async function GET_CURRENT_SEASON(context) {
    try {
        const response = await request('get', '', {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v2/seo/seasonal_offerings/etabl`);

        context.commit('SET_SEASON_CURRENT', response);
        return response;
    } catch (error) {
        return void console.error(error);
    }
}

export {
    GET_HISTORY,
    GET_MARKERS,
    FETCH_ADVERTISMENT,
    FETCH_ADVERTISMENT_BY_ID,
    GET_ARTICLES,
    GET_ARTICLE_BY_SLUG,
    GET_ARTICLES_TAGS,
    SET_ARTICLE_REVIEW,
    GET_CURRENT_SEASON,
};
