function CURRENT_CATEGORY(state) {
    return state.currentCategory;
};

function CATEGORIES(state) {
    return state.categories;
};

function NCATEGORIES(state) {
    return state.newCategories;
};

function FILTERS(state) {
    return state.filters;
};

function PRODUCTS(state) {
    return state.products;
};

function CATEGORIES_PREPARED(state) {
    return state.categoriesPrepared;
};

function FREE_DELIVERY(state) {
    return state.freeDelivery;
};

function CATEGORIES_FREE_DELIVERY_PREPARED(state) {
    return state.categoriesFreeDeliveryPrepared;
};

function CATEGORIES_URL_TO_ID(state) {
    return state.categoriesUrlToId;
};

function CATEGORIES_ID_TO_URL(state) {
    return state.categoriesIdToUrl;
};

function CATEGORIES_META(state) {
    return state.meta;
};

function TITLE(state) {
    return state.title;
};

function DESCRIPTION(state) {
    return state.description;
};

function CURRENT_CATALOG_CATEGORY(state) {
    return state.currentCatalogCategory;
};

function CURRENT_NCATEGORY(state) {
    return state.newCurrentCategory;
};

export {
    TITLE,
    DESCRIPTION,
    FILTERS,
    PRODUCTS,
    CATEGORIES,
    FREE_DELIVERY,
    CURRENT_CATEGORY,
    CATEGORIES_META,
    CATEGORIES_PREPARED,
    CATEGORIES_URL_TO_ID,
    CATEGORIES_ID_TO_URL,
    CATEGORIES_FREE_DELIVERY_PREPARED,
    CURRENT_CATALOG_CATEGORY,

    NCATEGORIES,
    CURRENT_NCATEGORY,
};
