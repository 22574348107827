function prepare(raw, parent_section_id = null, path = '') {
    const prepared = {};

    for (const { section_id, name, nested_categories, free_delivered_count } of raw) {
        prepared[section_id] = {
            section_id,
            name,
            parent_section_id,
            path: section_id,
            free_delivered_count,
        };

        if (nested_categories) {
            const nested = prepare(nested_categories, section_id, path);

            prepared[section_id].nested_categories = nested;

            for (const { path: nested_path } of Object.values(nested)) {
                prepared[section_id].path += `:${nested_path}`;
            }
        }
    }

    return prepared;

};

function UrlToId(raw) {

    const urls = {};

    for (const { url, section_id, nested_categories } of raw) {
        urls[url] = section_id;

        if (nested_categories) {
            const nested = UrlToId(nested_categories);

            for(const [key, value] of Object.entries(nested)) {
                urls[key] = value;
            }
        }
    }

    return urls;
};

function IdToUrl(raw) {

    const ids = {};

    for (const { url, section_id, nested_categories } of raw) {
        ids[section_id] = url;

        if (nested_categories) {
            const nested = IdToUrl(nested_categories);

            for(const [key, value] of Object.entries(nested)) {
                ids[key] = value;
            }
        }
    }

    return ids;
};

function find(section_id, prepared) {
    const keys = Object.keys(prepared);

    for (let i = 0; i <= keys.length; i++) {
        if (!prepared[keys[i]]?.path.includes(section_id)) {
            continue;
        } else if (keys[i] === section_id) {
            return prepared[keys[i]];
        } else {
            return find(section_id, prepared[keys[i]].nested_categories);
        }
    }
}


export {
    find,
    UrlToId,
    IdToUrl,
    prepare,
};
