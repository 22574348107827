import { request } from 'src/boot/axios';
import { sortCity } from 'assets/helpers/locations.js';

async function UPDATE_CITY_ID(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    try {
        await request('patch', `users/${context.state.id}`, {
            data: payload,
            token: context.rootState.user.token,
        });
    } catch (error) {
        return void console.error(error);
    }
};

async function GET_LOCATION_BY_ID(context, data) {
    return await request('get', `locations/${data}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
    });
};

async function GET_CITY_BY_ID(context, data) {
    const defaultCityId = '17c304fd-27c4-11e6-a3e4-001e6784c60c';

    const cityId = data.city_id || defaultCityId;

    return await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/points/cities/${data.city_id}`);
};

async function GET_LOCATIONS(context) {
    if(context?.state?.locations) {
        context.state.locations.results
            .forEach((item) => {
                let region = sortCity(item);

                const city = region.cities
                    ?.find(({ id }) => {
                        return id === context.rootGetters['user/USER_CITY_ID'];
                    });

                if (city) {
                    if(city.slug != 'belgorod')
                        context.commit('SET_CITY', city);
                    else
                        context.commit('SET_CITY', city);
                    context.commit('SET_REGION_NAME', item);
                };
            });

        return context.state.locations;
    }; 

    const result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/points/regions`);

    context.commit('SET_LOCATIONS', result);

    result.results
        .forEach((item) => {
            let region = sortCity(item);

            const city = region.cities
                ?.find(({ id }) => {
                    return id === context.rootGetters['user/USER_CITY_ID'];
                });

            if (city) {
                if(city.slug != 'belgorod')
                    context.commit('SET_CITY', city);
                else
                    context.commit('SET_CITY', city);
                context.commit('SET_REGION_NAME', item);
            };
        });

    return result;
};

export {
    UPDATE_CITY_ID,
    GET_LOCATIONS,
    GET_LOCATION_BY_ID,
    GET_CITY_BY_ID,
};
