function SET_ENTRY_AUTH(state, payload) {
    state.entryAuth = payload;
};

function SET_PHONE_NUMBER(state, payload) {
    state.phoneNumber = payload;
};

export {
    SET_ENTRY_AUTH,
    SET_PHONE_NUMBER,
};