<template lang="pug">
router-view
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'App',
});
</script>
