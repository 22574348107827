function SET_BASKET(state, payload) {
    state.basket = payload;
};

function SET_CITY_DELIVERY(state, payload) {
    state.cityDelivery = payload;
};

function SET_BASKET_COUNT(state, payload) {
    state.basketCount = payload;
};

function SET_INVENTORIES(state, payload) {
    state.inventories = payload;
};

function SET_DRUGSTORE(state, paylod) {

};

function SET_PAYMENT_METHOD(state, payload) {
    state.paymentMethod = payload;
};

function SET_BASKET_PRODUCTS(state, payload) {
    state.products = payload;
};

function SET_BASKET_PRODUCTS_AFTER_ORDER(state, payload) {
    state.productsAfterOrder = payload;
};

function SET_DELIVERY_ADDRESS(state, payload) {
    state.deliveryAddress = payload;
}

function SET_BASKET_STATE(state, payload) {
    state.basketVariant = payload.variant;
    state.basketDate = payload.date;
}

function SET_BASKET_LOADING(state, payload) {
    state.loading = payload;
};

function SET_ADDRESSES(state, payload) {
    state.addresses = payload;
};

function SET_USER_PROMOCODES(state, payload) {
    state.promocodes = payload;
};

export {
    SET_BASKET,
    SET_CITY_DELIVERY,
    SET_BASKET_COUNT,
    SET_INVENTORIES,
    SET_PAYMENT_METHOD,
    SET_BASKET_PRODUCTS,
    SET_BASKET_PRODUCTS_AFTER_ORDER,
    SET_DELIVERY_ADDRESS,
    SET_BASKET_STATE,
    SET_BASKET_LOADING,
    SET_ADDRESSES,
    SET_USER_PROMOCODES,
};