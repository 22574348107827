export default function () {
    return {
        loading: false,
        products: null,
        crumbPath: null,
        categoryTree: null,
        productsCount: null,
        catalogFilters: null,
        filtersFromURL: null,
        nestedCategories: null,
        productsFreeCatalog: null,
        tagDetail: null,
        tagsList: null,
        scrollId: null,
        catalogId: null,
    };
}
