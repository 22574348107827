function SET_BASKET(state, payload) {
    state.basket = payload;
};

function SET_BASKET_DELIVERY_CITIES(state, payload) {
    state.deliveryCities = payload;
};

function SET_BASKET_METHOD_WAY_GET(state, payload) {
    state.methodWayGet = payload;
};

function SET_BASKET_METHOD_WAY_DATE(state, payload) {
    state.methodWayDate = payload;
};

function SET_BASKET_LOADING(state, payload) {
    state.loading = payload;
};

function SET_CITY_DELIVERY(state, payload) {
    state.cityDelivery = payload;
};

function SET_CITY_DELIVERY_GOODS_LIST(state, payload) {
    state.cityDeliveryGoodsList = payload;
};

function SET_BASKET_ORDER_DATA(state, payload) {
    state.order = payload;
};

function SET_BASKET_PAYMENT_METHOD(state, payload) {
    state.order.payment_method = payload;
};

function SET_BASKET_FULL_LOADING(state, payload) {
    state.fullLoading = payload;
};

function SET_BASKET_ALL_DRUGSTORES(state, payload) {
    state.allDrustores = payload;
};

function SET_BASKET_INVENTORY_LIST(state, payload) {
    state.inventoryList = payload;
};

function SET_BASKET_HAS_DELIVERY(state, payload) {
    state.hasDelivery = payload;
};

function SET_BASKET_DELIVERY_GOODS_LIST(state, payload) {
    state.deliveryGoodsList = payload;
};

function SET_BASKET_COUNT(state, payload) {
    state.basketCount = payload;
};

function SET_PROMO_CODE_STATUS(state, payload) {
    state.promoCodeStatus = payload;
};

function DROP_PROMO_CODE_STATUS(state) {
    state.promoCodeStatus = null;
}

export {
    SET_BASKET,
    SET_BASKET_COUNT,
    SET_CITY_DELIVERY,
    SET_BASKET_LOADING,
    SET_PROMO_CODE_STATUS,
    SET_BASKET_ORDER_DATA,
    SET_BASKET_HAS_DELIVERY,
    SET_BASKET_FULL_LOADING,
    SET_BASKET_INVENTORY_LIST,
    SET_BASKET_ALL_DRUGSTORES,
    SET_BASKET_PAYMENT_METHOD,
    SET_BASKET_METHOD_WAY_GET,
    SET_BASKET_METHOD_WAY_DATE,
    SET_BASKET_DELIVERY_CITIES,
    SET_CITY_DELIVERY_GOODS_LIST,
    SET_BASKET_DELIVERY_GOODS_LIST,
    DROP_PROMO_CODE_STATUS,
};
