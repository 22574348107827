import { prepare, UrlToId, IdToUrl } from 'assets/helpers/categories';

function SET_CURRENT_CATEGORY(state, payload) {
    state.currentCategory = payload;
};

function SET_CATEGORIES(state, payload) {
    state.categories = payload;
};

function SET_NCATEGORIES(state, payload) {
    state.newCategories = payload;
};

function SET_FILTERS(state, payload) {
    state.filters = payload;
};

function SET_PRODUCTS(state, payload) {
    state.products = payload;
};

function SET_FREE_DELIVERY(state, payload) {
    state.freeDelivery = payload;
};

function SET_CATEGORIES_PREPARED(state, payload) {
    state.categoriesPrepared = prepare(payload);
};

function SET_CATEGORIES_FREE_DELIVERY_PREPARED(state, payload) {
    state.categoriesFreeDeliveryPrepared = prepare(payload);
};

function SET_CATEGORIES_URL_TO_ID(state, payload) {
    state.categoriesUrlToId = UrlToId(payload);
};

function SET_CATEGORIES_ID_TO_URL(state, payload) {
    state.categoriesIdToUrl = IdToUrl(payload);
};

function SET_CATEGORIES_FREE_DELIVERY(state, payload) {
    state.categoriesFreeDelivery = payload;
};

function SET_CATEGORIES_META(state, payload) {
    state.meta = payload;
};

function SET_CATEGORIES_TITLE(state, payload) {
    state.title = payload;
};

function SET_CATEGORIES_DESCRIPTION(state, payload) {
    state.description = payload;
};

function CLEAN_CATEGORIES_META(state) {
    state.meta = null;
};

function SET_CURRENT_CATALOG_CATEGORY(state, payload) {
    state.currentCatalogCategory = payload;
};

function SET_CURRENT_NCATEGORY(state, payload) {
    state.newCurrentCategory = payload;
};

export {
    SET_FILTERS,
    SET_PRODUCTS,
    SET_CATEGORIES,
    SET_FREE_DELIVERY,
    SET_CURRENT_CATEGORY,
    SET_CATEGORIES_META,
    SET_CATEGORIES_PREPARED,
    SET_CATEGORIES_URL_TO_ID,
    SET_CATEGORIES_ID_TO_URL,
    SET_CATEGORIES_FREE_DELIVERY,
    SET_CATEGORIES_FREE_DELIVERY_PREPARED,
    CLEAN_CATEGORIES_META,
    SET_CATEGORIES_TITLE,
    SET_CATEGORIES_DESCRIPTION,
    SET_CURRENT_CATALOG_CATEGORY,

    SET_NCATEGORIES,
    SET_CURRENT_NCATEGORY,
};
