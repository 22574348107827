function stylesAccessibility(state) {
    return state.stylesAccessibility;
}

function isActiv(state) {
    return state.isActiv;
}

function isActivPanel(state) {
    return state.isActivPanel;
}

function count(state) {
    return state.count;
}

export {
    stylesAccessibility,
    isActiv,
    isActivPanel,
    count,
};
