function ENTRY_AUTH(state) {
    return state.entryAuth;
};

function PHONE_NUMBER(state) {
    return state.phoneNumber;
};

export {
    ENTRY_AUTH,
    PHONE_NUMBER,
};
