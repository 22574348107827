import { deepCopy } from 'assets/helpers';

function BASKET_INFO(state) {
    return state.basket;
};

function BASKET_COUNT(state) {
    return state.basketCount?.goods_list.length;
};


function BASKET_DATE_OF_DELIVERY(state) {
    let date_of_delivery = state.basket?.date_of_delivery;

    if (!date_of_delivery) {
        const date = new Date();

        date.setDate(date.getDate() + 1);
        date_of_delivery = date.toISOString();
    }

    return new Date(date_of_delivery)
        .toLocaleDateString('ru-RU');
};

function BASKET_GOODS_LIST(state) {
    return state.basket.goods_list;
};

function BASKET_SHORT_GOODS_LIST(state) {
    return state.basketCount.goods_list;
};

function BASKET_SHOW_DELIVERY_METHOD(state) {
    return state.cityDeliveryGoodsList?.today[0]?.goods_list
        .some(({ checked }) => checked);
};

function BASKET_PRICE_WITHOUT_SALE(state) {
    return state.basket.total_products_price;
}

function BASKET_DISCOUNT(state) {
    return state.basket.discount;
};

function BASKET_TOMORROW_INVENTORY_LIST(state) {
    return deepCopy(state.inventoryList)?.filter((x) => {
        x.list_items = x.list_items.filter((item) => {
            if (!item.is_preorder) {
                if (!x.list_not_available?.length) x.list_not_available = [];
                x.list_not_available.push(item);
                return false;
            }
            return true;
        });

        return x.list_items.length;
    });
};

function BASKET_IS_TODAY(state) {
    return state.basket.is_today;
};

function BASKET_TODAY_GOODS_LIST(state) {
    return state.basket.today_goods_list;
};

function BASKET_TODAY_TOTAL_COUNT(state) {
    return state.basket?.today_total_count;
};

function BASKET_TODAY_TOTAL_PRICE(state) {
    return state.basket.today_total_price;
};

function BASKET_TODAY_TOTAL_PRODUCTS_PRICE(state) {
    return state.basket.today_total_products_price;
};

function BASKET_TODAY_ALL_CHECKED(state) {
    return state.basket.today_goods_list
        .filter(({ available, max_store_quantity }) => available && max_store_quantity)
        .every(({ checked }) => checked);
};

function BASKET_TODAY_INVENTORY_LIST(state) {
    return deepCopy(state.inventoryList)?.filter((x) => {

        x.list_items = x.list_items.filter((item) => {
            if (!item.is_stock) {
                if (!x.list_not_available?.length) x.list_not_available = [];
                x.list_not_available.push(item);
                return false;
            }
            return true;
        });

        return x.list_items.length;
    });
};

function BASKET_TOTAL_COUNT(state) {
    return state.basket?.total_count;
};

function BASKET_TOTAL_PRODUCTS(state) {
    return state.basket.total_products;
};

function BASKET_TOTAL_PRICE(state) {
    return state.basket.total_price;
};

function BASKET_TOTAL_PRODUCTS_PRICE(state) {
    return state.basket.total_products_price;
}

function BASKET_ALL_CHECKED(state) {
    return state.basket.goods_list
        .filter(({ available}) => available)
        .every(({ checked }) => checked);
};

function BASKET_DELIVERY_ALL_CHECKED(state) {
    return state.cityDeliveryGoodsList?.today[0]?.goods_list
        .every(({ checked }) => checked);
};

function BASKET_DELIVERY_CITIES(state) {
    return state.deliveryCities;
};

function BASKET_DRUGSTORE_ID(state) {
    return state.basket?.drugstore_id;
};

function BASKET_DELIVERY_PRICE(state) {
    return state.basket.delivery_price;
};

function BASKET_METHOD_WAY_GET(state) {
    return state.methodWayGet;
};

function BASKET_METHOD_WAY_DATE(state) {
    return state.methodWayDate;
};

function BASKET_LOADING(state) {
    return state.loading;
};

function BASKET_ONE_SELECTED_PRESCRIPTION_ITEM(state) {
    return !!(state.basket.goods_list.find((item) => item.checked && item.is_prescription));
};

function BASKET_CITY_DELIVERY(state) {
    return state.cityDelivery;
};

function BASKET_CITY_DELIVERY_GOODS_LIST(state) {
    return state.cityDeliveryGoodsList;
};

function BASKET_ORDER_DATA(state) {
    return state.order;
};

function BASKET_PAYMENT_METHOD(state) {
    return state.order.payment_method;
};

function BASKET_FULL_LOADING(state) {
    return state.fullLoading;
};

function BASKET_ALL_DRUGSTORES(state) {
    return state.allDrustores;
};

function BASKET_INVENTORY_LIST(state) {
    return state.inventoryList;
};

function BASKET_IMPOSSIBLE_ORDER_LIST(state) {
    return state.basket?.impossibleOrderList;
}

function BASKET_HAS_DELIVERY(state) {
    return !!state.hasDelivery?.length;
};

function BASKET_DELIVERY_GOODS_LIST(state) {
    return state.deliveryGoodsList;
};

function BASKET_PROMO_DISCOUNT(state) {
    return state.basket.promo_discount;
};

function BASKET_PROMO_CODE_STATUS(state) {
    return state.promoCodeStatus;
}

export {
    BASKET_INFO,
    BASKET_COUNT,
    BASKET_LOADING,
    BASKET_IS_TODAY,
    BASKET_DISCOUNT,
    BASKET_ORDER_DATA,
    BASKET_GOODS_LIST,
    BASKET_ALL_CHECKED,
    BASKET_TOTAL_COUNT,
    BASKET_TOTAL_PRICE,
    BASKET_FULL_LOADING,
    BASKET_DRUGSTORE_ID,
    BASKET_HAS_DELIVERY,
    BASKET_CITY_DELIVERY,
    BASKET_PROMO_DISCOUNT,
    BASKET_INVENTORY_LIST,
    BASKET_METHOD_WAY_GET,
    BASKET_METHOD_WAY_DATE,
    BASKET_TOTAL_PRODUCTS,
    BASKET_DELIVERY_PRICE,
    BASKET_PAYMENT_METHOD,
    BASKET_ALL_DRUGSTORES,
    BASKET_DELIVERY_CITIES,
    BASKET_SHORT_GOODS_LIST,
    BASKET_DATE_OF_DELIVERY,
    BASKET_TODAY_GOODS_LIST,
    BASKET_TODAY_ALL_CHECKED,
    BASKET_TODAY_TOTAL_COUNT,
    BASKET_TODAY_TOTAL_PRICE,
    BASKET_PROMO_CODE_STATUS,
    BASKET_PRICE_WITHOUT_SALE,
    BASKET_DELIVERY_GOODS_LIST,
    BASKET_TOTAL_PRODUCTS_PRICE,
    BASKET_TODAY_INVENTORY_LIST,
    BASKET_DELIVERY_ALL_CHECKED,
    BASKET_IMPOSSIBLE_ORDER_LIST,
    BASKET_TOMORROW_INVENTORY_LIST,
    BASKET_CITY_DELIVERY_GOODS_LIST,
    BASKET_TODAY_TOTAL_PRODUCTS_PRICE,
    BASKET_ONE_SELECTED_PRESCRIPTION_ITEM,
    BASKET_SHOW_DELIVERY_METHOD,
};
