function SET_FILTERS_FROM_URL(state, payload) {
    state.filtersFromURL = payload;
};

function SET_CATALOG_FILTERS(state, payload) {
    state.catalogFilters = payload;
};

function SET_PRODUCTS(state, payload) {
    state.products = payload;
};

function SET_PRODUCTS_COUNT(state, payload) {
    state.productsCount = payload;
};

function SET_CATEGORY_TREE(state, payload) {
    state.categoryTree = payload;
};

function SET_NESTED_CATEGORIES(state, payload) {
    state.nestedCategories = payload;
};

function SET_CRUMB_PATH(state, payload) {
    state.crumbPath = payload;
};

function SET_LOADING(state, payload) {
    state.loading = payload;
};

function SET_PRODUCTS_FREE_CATALOG(state, payload) {
    state.productsFreeCatalog = payload;
};

function SET_TAG_DETAIL(state, payload) {
    state.tagDetail = payload;
};

function SET_ALL_TAGS(state, payload) {
    state.tagsList = payload;
};

function SET_CATALOG_SCROLL_ID(state, payload) {
    state.scrollId = payload;
};

function SET_CATALOG_ID(state, payload) {
    state.catalogId = payload;
};

function ADD_TAG_PRODUCTS(state, payload) {
    state.tagDetail.products = state.tagDetail.products.concat(payload.products);
    state.tagDetail.page = payload.page;
};

export {
    SET_LOADING,
    SET_PRODUCTS,
    SET_CRUMB_PATH,
    SET_CATEGORY_TREE,
    SET_PRODUCTS_COUNT,
    SET_TAG_DETAIL,
    SET_CATALOG_FILTERS,
    SET_FILTERS_FROM_URL,
    SET_NESTED_CATEGORIES,
    SET_PRODUCTS_FREE_CATALOG,
    SET_ALL_TAGS,
    SET_CATALOG_SCROLL_ID,
    SET_CATALOG_ID,
    ADD_TAG_PRODUCTS,
};