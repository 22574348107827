function BASKET_COUNT(state) {
    return state.localData?.products.length;
};

function BASKET_CHECKED_COUNT(state) {
    return state.localData?.products
        .filter(({ checked }) => checked).length;
};

function BASKET_PRODUCTS(state) {
    return state.localData?.products;
};

function BASKET_TOTAL_PRICE(state) {
    return state.localData?.products
        .reduce((previous, current) => {
            const {
                price,
                amount,
                checked,
            } = current;

            return checked
                ? previous + price * amount
                : previous;
        }, 0);
};

function BASKET_ALL_CHECKED(state) {
    return state.localData?.products
        .every(({ checked }) => checked);
};

function DRUGSTORE_ID(state) {
    return state.localData?.drugstoreId;
};

function FAVOURITES(state) {
    return state.localData?.favourites;
};

function FAVOURITES_COUNT(state) {
    return state.localData?.favourites.length;
};

export {
    FAVOURITES,
    BASKET_COUNT,
    DRUGSTORE_ID,
    BASKET_PRODUCTS,
    FAVOURITES_COUNT,
    BASKET_TOTAL_PRICE,
    BASKET_ALL_CHECKED,
    BASKET_CHECKED_COUNT,
};