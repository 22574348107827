import { LocalStorage, Cookies } from 'quasar';
import { addTime } from 'assets/helpers/index';

function SET_UID(state, uid) {
    state.uid = uid;
};

async function  SIGNOUT() {
    await Cookies.remove('_uid');
    await Cookies.remove('token');
    await LocalStorage.remove('token');
    

    if (process.env.CLIENT) {
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.reload(true);
    }
};


function SIGNIN(_, payload) {
    Cookies.set('token', payload, {
        expires: 10,
    });
    LocalStorage.set('token', payload);

    if (process.env.CLIENT) {
        window.location.reload(true);
        // window.location.replace('/');
    }
    try {
        ym(72234883,'reachGoal','user_signin');
    } catch {}
};

function SET_COUNT_OF_ACTIVE_ORDERS(state, payload) {
    state.activeOrders = payload;
};

function SET_TOKEN(state, payload) {
    state.token = payload;
};
function SET_HH_TOKEN(state, payload) {
    state.hhtoken = payload;
};
function SET_HH_RESUME(state, payload) {
    state.resume_hh = payload;
};

function SET_USER_DATA(state, payload) {
    Object.keys(payload)
        .forEach((key) => {
            state[key] = payload[key];
        });
};

function UPDATE_USER_INFO(state, payload) {
    state.info = payload;
};

function SET_USER_CITY_ID(state, payload) {
    state.city_id = payload;
};

function ADD_USER_FAVOURITE_PRODUCT(state, payload) {
    state.favoriteProducts.push(payload);
};

function ADD_USER_FAVOURITE_DRUGSTORE(state, payload) {
    state.favDrugstores.push(payload);
};

function DELETE_USER_FAVOURITE_PRODUCT(state, payload) {
    if(state.favProducts)
        state.favProducts = state.favProducts
            .filter((item) => item.id !== payload);
};

function DELETE_USER_FAVOURITE_DRUGSTORE(state, payload) {
    if(state.favDrugstores)
        state.favDrugstores = state.favDrugstores
            .filter((item) => item.id != payload);
};

function ADD_USER_SUBSCRIPTION(state, payload) {
    state.subscriptions.push(payload);
};

function DELETE_USER_SUBSCRIPTION(state, payload) {
    state.subscriptions = state.subscriptions
        .filter((item) => item !== payload);
};

function PREPARED_FREE_DELIVERY(state) {
    state.preparedFreeDelivery = true;
}

function SET_USER_PROMOCODES(state, payload) {
    state.promocodes = payload;
};

function SET_FRANCHISE_NAME(state, payload) {
    state.franchName = payload;
};

function SET_FRANCHISE_EMAIL(state, payload) {
    state.franchName = payload;
};

function SET_USER_FAV_COUNT(state, payload) {
    state.favCount = payload;
};

function SET_USER_FAV_DRUGSTORES(state, payload) {
    state.favDrugstores = payload;
};

function SET_USER_FAV_PRODUCTS(state, payload) {
    state.favProducts = payload;
}

function SET_USER_ORDERS(state, payload) {
    state.orders = payload;
}

function MORE_USER_ORDERS(state, payload) {
    state.orders.results = [...state.orders.results, ...payload.results];
    state.orders.page = payload.page;
}

function SET_CANCEL_ORDER_REASONS(state, payload) {
    state.reason_cancel = payload;
}

function SET_USER_PURCHASES(state, payload) {
    state.purchases = payload;
};

function SET_USER_PRODUCTS(state, payload) {
    state.products = payload;
};

function SET_USER_CATEGORIES(state, payload) {
    state.categories = payload;
};

function UPDATE_ORDER_FROM_LIST(state, payload) {
    let changedItem = state.orders.results.find((item) => item.id == payload.id);

    let indexEl = state.orders.results.indexOf(changedItem);

    state.orders.results.splice(indexEl, 1, payload);
};

function MORE_USER_PURCHASES(state, payload) {
    state.purchases.results = [...state.purchases.results, ...payload.results];
    state.purchases.page = payload.page;
};

function SET_USER_FAV(state, payload) {
    state.favourites = payload;
};

function ADD_USER_FAV(state, payload) {
    state.favourites.products = [...state.favourites.products, ...payload];
};

function UPDATE_USER_ADDRESS(state, payload) {
    state.address = payload;
};

function SET_BLOCK(state) {
    state.block = true;
};

export {
    SIGNIN,
    SIGNOUT,
    SET_UID,
    SET_TOKEN,
    SET_USER_DATA,
    SET_USER_ORDERS,
    MORE_USER_ORDERS,
    SET_USER_CITY_ID,
    UPDATE_USER_INFO,
    SET_FRANCHISE_NAME,
    SET_FRANCHISE_EMAIL,
    SET_USER_PROMOCODES,
    ADD_USER_SUBSCRIPTION,
    PREPARED_FREE_DELIVERY,
    SET_CANCEL_ORDER_REASONS,
    DELETE_USER_SUBSCRIPTION,
    SET_COUNT_OF_ACTIVE_ORDERS,
    ADD_USER_FAVOURITE_PRODUCT,
    ADD_USER_FAVOURITE_DRUGSTORE,
    DELETE_USER_FAVOURITE_PRODUCT,
    DELETE_USER_FAVOURITE_DRUGSTORE,
    SET_USER_FAV_COUNT,
    SET_USER_FAV_DRUGSTORES,
    SET_USER_FAV_PRODUCTS,
    SET_USER_PURCHASES,
    SET_USER_PRODUCTS,
    SET_USER_CATEGORIES,
    UPDATE_ORDER_FROM_LIST,
    MORE_USER_PURCHASES,
    SET_USER_FAV,
    ADD_USER_FAV,
    UPDATE_USER_ADDRESS,
    SET_BLOCK,
    SET_HH_TOKEN,
    SET_HH_RESUME,
};
