import { request } from 'src/boot/axios';

async function GET_DRUGSTORES(context, query) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query,
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/doctors/pharmacies`); 

    return result;
};

async function GET_DOCTORS(context, query) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query,
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/doctors/doctors`); 

    return result;
}

async function GET_SCHEDULES(context, query) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query,
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/doctors/calendar`); 

    return result;
}

async function CREATE_APPOINTMENT(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        data,
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/doctors/reception`); 
}

async function GET_APPOINSMENTS(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/doctors/receptions`); 

    return result;
}

async function CANCEL_APPOINSMENT(context, id) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('delete', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/doctors/reception/${id}`); 

    return result;
}

export {
    GET_DRUGSTORES,
    GET_DOCTORS,
    GET_SCHEDULES,
    CREATE_APPOINTMENT,
    GET_APPOINSMENTS,
    CANCEL_APPOINSMENT,
};