import { request } from 'src/boot/axios';
import { checkImpossible } from 'assets/helpers/basket.js';

async function GET_BASKET(context, data = {}) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', 'baskets', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    });

    context.commit('SET_BASKET_COUNT', result);

    // create impossible to order list
    result = await checkImpossible(context, result);

    context.commit('SET_BASKET', result);
    return context.state.basket;
};

async function BASKET_PUT_PRODUCT(context, { product, is_today, in_basket }) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('put', `baskets/product/${product.id}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        data: product,
        query: {
            is_today: is_today || false,
            in_basket,
        },
        token: context.rootState.user.token,
    });

    context.commit('SET_BASKET_COUNT', result);

    // create impossible to order list
    result = await checkImpossible(context, result);

    context.commit('SET_BASKET', result);
    return context.state.basket;
};

async function BASKET_DEL_PRODUCT(context, { id, is_today = false, in_basket }) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('delete', `baskets/product/${id}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query: {
            is_today: is_today || false,
            in_basket,
        },
        token: context.rootState.user.token,
    });

    context.commit('SET_BASKET_COUNT', result);

    // create impossible to order list
    result = await checkImpossible(context, result);

    context.commit('SET_BASKET', result);
    return context.state.basket;
};

async function BASKET_SET_IS_TODAY(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('post', 'baskets/is-today', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query: {
            condition: data.condition,
        },
        token: context.rootState.user.token,
    });

    // create impossible to order list
    result = await checkImpossible(context, result);

    context.commit('SET_BASKET', result);
    return context.state.basket;
};

async function BASKET_TOGGLE_CHECK_CONDITION(context, { condition, is_today }) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('post', 'baskets/toggle-check', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query: {
            condition,
            is_today: is_today || false,
        },
        token: context.rootState.user.token,
    });

    context.commit('SET_BASKET_COUNT', result);
    
    // create impossible to order list
    result = await checkImpossible(context, result);

    context.commit('SET_BASKET', result);
    return context.state.basket;
};

async function DELETE_BASKET(context, data = {}) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    const result = await request('delete', 'baskets', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    });

    context.commit('SET_BASKET_COUNT', result);

    context.commit('SET_BASKET', result);
};

async function BASKET_GET_DELIVERY_CITIES(context) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('get', 'delivery/cities', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
    });
};

async function BASKET_GET_INVENTORY_LIST(context, { is_today }) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('get', 'baskets/inventory-list', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query: {
            is_today: is_today || false,
        },
        token: context.rootState.user.token,
    });
};

async function BASKET_SET_DRUGSTORE_ID(context, { drugstore_id, is_today }) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('post', 'baskets/drugstore-id', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        query: {
            drugstore_id,
            is_today: is_today || false,
        },
        token: context.rootState.user.token,
    });

    // create impossible to order list
    result = await checkImpossible(context, result);

    context.commit('SET_BASKET', result);
};

async function BASKET_RESET_DRUGSTORE_ID(context) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('post', 'baskets/drugstore-id', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    });

    context.commit('SET_BASKET', result);
};

async function BASKET_DESTINATION_CITY_ID(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    // сохраняем город доставки
    context.commit('SET_CITY_DELIVERY', data);

    const result = await request('post', `baskets/destination-city-id/${data.city_id || data}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    });

    // сохраняем список товаров города доставки
    context.commit('SET_CITY_DELIVERY_GOODS_LIST', result);

    return result;
};

async function BASKET_CREATE_ORDER(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('post',
        `orders${data.path}`, {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
            query: data.query,
            data: data.order,
            token: context.rootState.user.token,
        });
};

async function BASKET_GET_ORDER_PAYMENT_LINK(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('get', `orders/${data.path}`, {
        query: data.query,
        token: context.rootState.user.token,
    });
};

async function BASKET_GET_HAS_DELIVERY(context, data) {
    const defaultCityId = '17c304fd-27c4-11e6-a3e4-001e6784c60c';

    const cityId = data || defaultCityId;

    return await request('get', `delivery/tariffs/by-destination/${cityId}`);
}

async function BASKET_SET_PROMO_CODE(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    const result = await request('post', 'baskets/set-promo-code', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        data: {
            promo_code: data.promo_code,
        },
        token: context.rootState.user.token,
    });

    context.commit('SET_PROMO_CODE_STATUS', result.promo_code_status);

    context.commit('SET_BASKET', result);
}

async function BASKET_GET_COUNT(context) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', 'baskets/count', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    });
    
    context.commit('SET_BASKET_COUNT', result);
    context.commit('SET_BASKET', result);
    return context.state.basket;
}


export {
    GET_BASKET,
    DELETE_BASKET,
    BASKET_GET_COUNT,
    BASKET_PUT_PRODUCT,
    BASKET_DEL_PRODUCT,
    BASKET_SET_IS_TODAY,
    BASKET_CREATE_ORDER,
    BASKET_SET_PROMO_CODE,
    BASKET_GET_HAS_DELIVERY,
    BASKET_SET_DRUGSTORE_ID,
    BASKET_RESET_DRUGSTORE_ID,
    BASKET_GET_INVENTORY_LIST,
    BASKET_GET_DELIVERY_CITIES,
    BASKET_DESTINATION_CITY_ID,
    BASKET_GET_ORDER_PAYMENT_LINK,
    BASKET_TOGGLE_CHECK_CONDITION,
};
