import { request } from 'src/boot/axios';

async function GET_ALL_DRUGSTORES(context) {
    const { drugstores } = context.state;
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });


    const result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    },
    `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/pharmacies/all`);

    context.commit('SET_DRUGSTORES', result);

    return result;

};

async function GET_DRUGSTORE_BY_ID(context, { drugstoreId }) {
    const { city_id } = context.rootState.user;
    const { drugstores } = context.state;

    if (drugstores && city_id === drugstores.city_id) {
        return drugstores
            .find(({ drugstore_id }) => {
                return drugstore_id === drugstoreId;
            });
    } else {
        return await request('get', `drugstores/${drugstoreId}`, {
            headers: {
                'x-channel': 'web',
                'x-city-id': context.rootState.user.city_id,
            },
        });
    }
};

export {
    GET_ALL_DRUGSTORES,
    GET_DRUGSTORE_BY_ID,
};