import { date } from 'quasar';

export function toJSON(date) {
    const local = new Date(date);

    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    return local.toJSON();
};

export function convertBrithday(date) {
    if (date) {
        if (/[-]+/.test(date)) {
            return new Date(Date.parse(new Date(date).toISOString()))
                .toLocaleDateString('ru-RU').split('.').join('/');
        } else {
            return date
                .split('/')
                .reverse()
                .join('/');
        }
    } else {
        return null;
    }
};

export function convertGender(gender, options = ['Мужской', 'Женский']) {
    if (gender) {
        return gender === 'male'
            ? options[0]
            : options[1];
    } else {
        return null;
    }
};

export function convertPhone(phone) {
    if (phone) {
        if (/[\\s|(|)]+/.test(phone)) {
            const [part_1, part_2, part_3, part_4, part_5] = phone.split(' ');

            return `${[part_1, part_2.slice(1, 3), part_3, part_4, part_5].join('')}`;
        } else {
            const part_1 = phone.slice(0, 2);
            const part_2 = phone.slice(2, 5);
            const part_3 = phone.slice(5, 8);
            const part_4 = phone.slice(8, 10);
            const part_5 = phone.slice(10, 12);

            return `${part_1} (${part_2}) ${[part_3, part_4, part_5].join(' ')}`;
        }
    } else {
        return null;
    }
};

export function declensions(titles) {
    return function bindedDeclensions(amount) {
        const CASES = [2, 0, 1, 1, 1, 2];

        return titles[
            (amount % 100 > 4 && amount % 100 < 20)
                ? 2
                : CASES[(amount % 10 < 5) ? amount % 10 : 5]
        ];
    };
};

export function toIsoString(date) {
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';

    function pad(number) {
        const normal = Math.floor(Math.abs(number));

        return (normal < 10 ? '0' : '') + normal;
    };

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
};

export function is_expires(string) {
    const current_date = new Date();
    const current_date_offset = Math.abs(new Date().getTimezoneOffset()) / 60;

    current_date.setHours(current_date.getHours() - current_date_offset);

    return new Date(string).toISOString() <= current_date.toISOString();
};

export function addTime(message) {
    message.time = date.formatDate(Date.parse(message.createdAt), 'HH:mm');

    return message;
};

export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
};

export function offsetEl(el) {
    let rect = el.getBoundingClientRect(),
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect.top + scrollTop;
};

export function hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
    throw new Error('Bad Hex');
};
