import { request } from 'src/boot/axios';

async function QUERY_SUGGESTION(context, payload) {
    return await request('post', 'search/query-suggestion', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        data: payload,
    });
};

async function SEARCH_FILTERS(context, payload) {
    return await request('post', 'search/search', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        data: payload,
    });
};

async function SEARCH(context, payload) {
    return await request('post', 'search/search', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        data: payload,
    });
};

async function NEW_SUGGESTION(context, payload) {
    return await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        data: payload,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/products/suggest?query=${payload.query}`);
};

async function NEW_SEARCH_TERM(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        data: payload.data,
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/products/term-search?query=${payload.query}&page=${payload.page}&size=${payload.size}&scroll_id=${payload.scrollId}`);
};

async function NEW_SEARCH(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        data: payload.data,
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/products/search?query=${payload.query}&page=${payload.page}&size=${payload.size}&scroll_id=${payload.scrollId}`);
};

async function GET_PRODUCTS(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/products/get-by-ids?ids=${payload}&page=1&size=20`);

    return result;
}

async function GET_PRODUCTS_FROM_LETTER(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });
    let result = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/products/search-by-letter?query=${payload.query}&page=${payload.newPage}&size=100`);

    return result;
}

export {
    QUERY_SUGGESTION,
    SEARCH_FILTERS,
    SEARCH,
    NEW_SUGGESTION,
    NEW_SEARCH,
    NEW_SEARCH_TERM,
    GET_PRODUCTS,
    GET_PRODUCTS_FROM_LETTER,
};
