export default function () {
    return {
        id: null,
        uid: null,
        info: null,
        token: null,
        hhtoken:null,
        resume_hh: [],
        messages: [],
        city_id: process.env.DEFAULT_CITY_ID,
        preparedFreeDelivery: false,
        promocodes: null,
        franchName: null,
        franchEmail: null,
        favCount: 0,
        favProducts: null,
        favDrugstores: [],
        orders: null,
        reason_cancel: [],
        activeOrders: 0,
        purchases: null,
        products: null,
        categories: null,
        favourites: null,
        address: null,
        block: false,
    };
}
