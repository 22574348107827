function DEVICE(state) {
    return state.device;
};

function NOJS(state) {
    return state.noJs;
};

function BLOCK(state) {
    return state.block;
};

function PLATFORM(state) {
    return state.platform;
};

export {
    DEVICE,
    NOJS,
    BLOCK,
    PLATFORM,
};