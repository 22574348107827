function SET_SEARCH_RESULT(state, result) {
    state.searchResult = result;
};

function SET_SEARCH_FILTERS(state, payload) {
    state.searchFilters = payload;
};

function SET_FILTERS_FROM_URL(state, payload) {
    state.filtersFromURL = payload;
};

function SET_LOADING(state, payload) {
    state.loading = payload;
};

function SET_SEARCH_NRESULT(state, result) {
    state.searchResultNew = result;
};

function SET_SEARCH_NRESULTS_COUNT(state, result) {
    state.searchResultCount = result;
};

function SET_SEARCH_NRESULT_FILTERS(state, result) {
    state.searchFiltersNew = result;
};

function SET_SEARCH_SCROLL_ID(state, result) {
    state.scrollId = result;
};

function SET_SEARCH_QUERY(state, result) {
    state.searchQuery = result;
};

function SET_EXIT_ROUTE(state, payload) {
    state.searchExitRoute = payload;
};

function SET_SEARCH_REGIONS(state, payload) {
    state.searchRegions = payload;
}

export {
    SET_LOADING,
    SET_SEARCH_RESULT,
    SET_SEARCH_FILTERS,
    SET_FILTERS_FROM_URL,

    SET_SEARCH_NRESULT,
    SET_SEARCH_NRESULTS_COUNT,
    SET_SEARCH_NRESULT_FILTERS,
    SET_SEARCH_SCROLL_ID,
    SET_SEARCH_QUERY,
    SET_EXIT_ROUTE,
    SET_SEARCH_REGIONS,
};
