function SET_DISCOUNT(state, payload) {
    state.discount = payload;
};

function SET_ADVERTISMENT(state, payload) {
    state.advertisement = payload;
};

function SET_CACHE(state, payload) {
    state.cache = payload;
};

function SET_HISTORY(state, payload) {
    state.history = payload;
};

function SET_ARTICLES(state, payload) {
    state.articles = payload;
};

function SET_ARTICLE(state, payload) {
    state.article = payload;
};

function SET_SINGE_ADVERTISMENT(state, payload) {
    state.currentAdvertisment = payload;
};

function SET_ARTICLES_TAGS(state, payload) {
    state.articlesTags = payload;
}

function SET_SEASON_CURRENT(state, payload) {
    state.seasons = payload;
}

export {
    SET_CACHE,
    SET_HISTORY,
    SET_ARTICLES,
    SET_DISCOUNT,
    SET_ADVERTISMENT,
    SET_ARTICLE,
    SET_SINGE_ADVERTISMENT,
    SET_ARTICLES_TAGS,
    SET_SEASON_CURRENT,
};