export default function () {
    return {
        cache: null,
        discount: null,
        advertisement: null,
        history: null,
        articles: null,
        article: null,
        currentAdvertisment: null,
        articlesTags: null,
        seasons:null,
    };
}
