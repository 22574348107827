export default function () {
    return {
        loading: false,
        pushProducts: null,
        searchResult: null,
        searchFilters: null,
        filtersFromURL: null,
        searchResultNew: null,
        searchResultCount: null,
        searchFiltersNew: null,
        scrollId: null,
        searchQuery: null,
        searchExitRoute: '/',
        searchRegions: null,
    };
}
