function SET_DEVICE(state, payload) {
    state.device = payload;
};

function SET_NO_JS(state, payload) {
    state.noJs = payload;
};

function SET_BLOCK(state) {
    state.block = true;
};

function SET_PLATFORM(state, payload) {
    state.platform = payload;
};

export {
    SET_DEVICE,
    SET_NO_JS,
    SET_BLOCK,
    SET_PLATFORM,
};