import { LocalStorage } from 'quasar';
export class Storage {
    static getLocalToken() {
        return LocalStorage.getItem('token');
    }

    static saveLocalToken(token) {
        return LocalStorage.set('token', token);
    }

    static removeLocalToken() {
        return LocalStorage.remove('token');
    }

    static getLocalLocation() {
        return LocalStorage.getItem('location');
    }

    static saveLocalLocation(location) {
        return LocalStorage.set('location', location);
    }

    static removeLocalLocation() {
        return LocalStorage.remove('location');
    }

    static getLocalData() {
        return LocalStorage.getItem('localData');
    }

    static saveLocalData(payload) {
        return LocalStorage.set('localData', payload);
    }
}