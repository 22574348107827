import { request } from 'src/boot/axios';

async function CREATE_REGISTRATION(context, data) {

    let result = await request('post', '', {
        data,
    }, 'https://partners.etabl.ru/api/v1/integrations-partners-admin/offers'); 

    return result;
};

export {
    CREATE_REGISTRATION,
};