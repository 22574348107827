export default function () {
    return {
        basketCount: null,
        paymentMethod: 'online',
        products: null,
        productsAfterOrder: null,
        deliveryAddress: null,
        basketVariant: null,
        basketDate: null,
        loading: false,
        addresses: null,
        promocodes: null,
    };
}