export default function () {
    return {
        order: {
            channel: 'web',
            customer: {
                phone: '',
                first_name: '',
                middle_name: '',
                gender: '',
                brithday: '',
            },
            delivery: {
                type: 0,
                date: null,
                time_from: null,
                time_until: null,
                address: {
                    fias: '',
                    representation: '',
                    comment: '',
                },
                cost: 0,
            },
            comments: [],
            payment_method: 'online',
            recipes: [],
        },
        loading: false,
        hasDelivery: false,
        cityDelivery: null,
        allDrustores: null,
        fullLoading: false,
        inventoryList: null,
        deliveryCities: null,
        deliveryGoodsList: null,
        cityDeliveryGoodsList: null,
        methodWayGet: [3, null, false],
        methodWayDate: null,
        promoCodeStatus: null,
    };
}
