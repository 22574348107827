import { request } from 'src/boot/axios';

async function GET_PREVIEWS_BY_CATEGORY(context, data) {
    const path = data.free_delivery ? 'delivery-catalog' : 'catalog';

    return await request('post', `search/${path}/${data.section_id}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        data,
    });
};


async function GET_FREE_CATALOG(context, data) {

    const result = await request('post', 'search/full-delivery-catalog', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        data,
    });

    context.commit('SET_PRODUCTS_FREE_CATALOG', result.results);

    return result;
};

async function GET_TAG_DETAIL(context, data) {

    const result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        data,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v2/seo/tagpages/etabl/${data.slug}?page=${data.page}`);

    if(data.showMore) {
        context.commit('ADD_TAG_PRODUCTS', result);
    } else {
        context.commit('SET_TAG_DETAIL', result);
    }
        

    return result;
};

async function GET_ALL_TAGS(context) {

    const result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v2/seo/tagpages/receive_all`);

    context.commit('SET_ALL_TAGS', result);

    return result;
};

async function GET_PREVIEWS_BY_NCATEGORY(context, payload) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    return await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        data: payload.data,
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/products/category/${payload.id}?page=${payload.page}&size=${payload.limit}&scroll_id=${payload.scrollId}`);
};

export {
    GET_PREVIEWS_BY_CATEGORY,
    GET_FREE_CATALOG,
    GET_TAG_DETAIL,
    GET_ALL_TAGS,

    GET_PREVIEWS_BY_NCATEGORY,
};