function SEARCH_RESULT(state) {
    return state.searchResult;
}

function SEARCH_PRODUCTS(state) {
    return state.searchResult?.results;
}

function SEARCH_FILTERS(state) {
    return state.searchFilters;
}

function FILTERS_FROM_URL(state) {
    return state.filtersFromURL;
}

function LOADING(state) {
    return state.loading;
};

function SEARCH_RESULT_NEW(state) {
    return state.searchResultNew;
};

function SEARCH_COUNT(state) {
    return state.searchResultCount;
};

function SEARCH_FILTERS_NEW(state) {
    return state.searchFiltersNew.filters;
};

function SCROLL_ID(state) {
    return state.scrollId;
};

function SEARCH_QUERY(state) {
    return state.searchQuery;
};

function SEARCH_EXIT_ROUTE(state) {
    return state.searchExitRoute;
};

function SEARCH_REGIONS(state) {
    return state.searchRegions;
};

export {
    LOADING,
    SEARCH_RESULT,
    SEARCH_FILTERS,
    SEARCH_PRODUCTS,
    FILTERS_FROM_URL,

    SEARCH_RESULT_NEW,
    SEARCH_COUNT,
    SEARCH_FILTERS_NEW,
    SCROLL_ID,
    SEARCH_QUERY,
    SEARCH_EXIT_ROUTE,
    SEARCH_REGIONS,
};
