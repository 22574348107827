function BASKET_INFO(state) {
    return state.basket;
};

function BASKET_COUNT(state) {
    return state.basketCount;
};

function BASKET_INVENTORIES(state) {
    return state.inventories;
};

function BASKET_PRODUCTS(state) {
    return state.products;
};

function BASKET_AFTER_ORDER(state) {
    return state.productsAfterOrder;
};

function DELIVERY_ADDRESS(state) {
    return state.deliveryAddress;
};

function BASKET_STATE(state) {
    return [state.basketVariant, state.basketDate];
};

function BASKET_LOADING(state) {
    return state.loading;
};

function ADDRESSES(state) {
    return state.addresses;
};

function PROMOCODES(state) {
    return state.promocodes;
};

export {
    BASKET_INFO,
    BASKET_COUNT,
    BASKET_INVENTORIES,
    BASKET_PRODUCTS,
    BASKET_AFTER_ORDER,
    DELIVERY_ADDRESS,
    BASKET_STATE,
    BASKET_LOADING,
    ADDRESSES,
    PROMOCODES,
};