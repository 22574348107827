function CITY(state) {
    return state.city;
};

function REGION_NAME(state) {
    return state.regionName;
};

function LOCATIONS(state) {
    return state.locations;
};

export {
    CITY,
    REGION_NAME,
    LOCATIONS,
};
