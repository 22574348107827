function CHANGE_CITY(state) {
    state.city = 'Шебекино';
};

function SET_CITY(state, payload) {
    if(payload?.slug =='belgorod' || !payload)
        state.city = null;
    else
        state.city = payload;
};

function SET_REGION_NAME(state, payload) {
    state.regionName = payload;
};

function SET_LOCATIONS(state, payload) {
    state.locations = payload;
}

export {
    CHANGE_CITY,
    SET_CITY,
    SET_REGION_NAME,
    SET_LOCATIONS,
};
