function sortCity(region) {
    let arr = [];
    if (region.region_id === '6c11e6bf-5649-11eb-9d79-00155d0c026e') {
        let topCityes = [];
        let middleCityes = [];

        for (let city of region.cities) {
            if (city.city_id === '17c304fd-27c4-11e6-a3e4-001e6784c60c' || city.city_id === '94af0082-27c5-11e6-a3e4-001e6784c60c') {
                topCityes.push(city);
            } else {
                middleCityes.push(city);
            }
        }

        middleCityes.sort((a, b) => {
            if (a.city_name.toLowerCase() < b.city_name.toLowerCase()) {
                return -1;
            } else if (a.city_name.toLowerCase() > b.city_name.toLowerCase()) {
                return 1;
            } else return 0;
        });

        region.cities = arr.concat(topCityes.concat(middleCityes));
    }
  
    return region;
}

export {
    sortCity,
};