import { LocalStorage } from 'quasar';

function SET_LOCAL_STORAGE(state) {
    LocalStorage.set('isActivAccessibilityUser', state);
};

function SET_ALL_SETTINGS(state, payload) {
    for(let key in payload){
        if (payload.hasOwnProperty(key)){
            state[key] = payload[key];
        }
    }
};

function SET_IMAGES(state, payload) {
    state.stylesAccessibility.images = payload;
    SET_LOCAL_STORAGE(state);
};

function SET_DEFAULT_DATA(state) {
    state.stylesAccessibility.images = 'block';
    state.stylesAccessibility.letterSpacing = 0;
    state.isActiv = false;
    state.count = 0;
    SET_LOCAL_STORAGE(state);
};

function ADD_COUNT(state) {
    if (state.count < 6) {
        state.count += 2;
        SET_LOCAL_STORAGE(state);
    }
};

function REMOVE_COUNT(state) {
    if (state.count !== 0) {
        state.count -= 2;
        SET_LOCAL_STORAGE(state);
    }
};

function ADD_LETTER_SPACING(state) {
    if (state.stylesAccessibility.letterSpacing < 0.1) {
        state.stylesAccessibility.letterSpacing += 0.1;
        SET_LOCAL_STORAGE(state);
    }
};

function REMOVE_LETTER_SPACING(state) {
    if (state.stylesAccessibility.letterSpacing > -0.1) {
        state.stylesAccessibility.letterSpacing -= 0.1;
        SET_LOCAL_STORAGE(state);
    }
};

function SET_ACTIV(state, payload) {
    state.isActiv = payload;
    SET_LOCAL_STORAGE(state);
};

function SET_ACTIV_PANEL(state) {
    state.isActivPanel = !state.isActivPanel;
    SET_LOCAL_STORAGE(state);
};

export {
    SET_ACTIV,
    SET_ACTIV_PANEL,
    SET_DEFAULT_DATA,
    SET_ALL_SETTINGS,
    SET_IMAGES,
    ADD_COUNT,
    ADD_LETTER_SPACING,
    REMOVE_COUNT,
    REMOVE_LETTER_SPACING,
};
