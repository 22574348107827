function PRODUCT(state) {
    return state.product;
};

function PRODUCT_ANALOGS(state) {
    return state.productAnalogs;
};

function PRODUCT_FORM_FACTOR(state) {
    return state.productFormFactor;
};

function PRODUCT_ADDITIONAL_SALES(state) {
    return state.productAdditionalSales;
}

function CURRENT_PRODUCT_ANALOGS(state) {
    return state.currentProductAnalogs;
}

export {
    PRODUCT,
    PRODUCT_ANALOGS,
    PRODUCT_FORM_FACTOR,
    PRODUCT_ADDITIONAL_SALES,
    CURRENT_PRODUCT_ANALOGS,
};
