function DISCOUNT(state) {
    return state.discount?.products;
};

function ADVERTISMENT(state) {
    return state.advertisement;
};

function CACHE(state) {
    return state.cache;
};

function HISTORY(state) {
    return state.history?.products;
};

function ARTICLES(state) {
    return state.articles;
};

function ARTICLE(state) {
    return state.article;
};

function CURRENT_ADVERTISMENT(state) {
    return state.currentAdvertisment;
};

function ARTICLES_TAGS(state) {
    return state.articlesTags;
};

function SEASONS(state) {
    return state.seasons;
};

export {
    CACHE,
    HISTORY,
    DISCOUNT,
    ARTICLES,
    ARTICLE,
    ADVERTISMENT,
    CURRENT_ADVERTISMENT,
    ARTICLES_TAGS,
    SEASONS,
};
